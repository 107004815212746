import React from 'react';

export const OurStorySection: React.FC = () => {
  return (
    <div className="section-section">
      <div className="section-container">
        <h2>Our Story</h2>
        <p>Nisha and Tarak's paths likely crossed many times before they finally met. Tarak moved to San Francisco in 2008 after graduating from MIT, and Nisha arrived in the city in 2012 for her preliminary year at California Pacific Medical Center. Though they were just a few miles apart, it took over a decade and the magic of modern technology for this South Carolina-raised boy and Louisiana-bred girl to connect.</p>
        <p>Their first meeting was at the iconic Ferry Building in San Francisco. After hours of engaging conversation and a couple of scoops of ice cream from Humphry Slocombe (a bold choice for a chilly, windy day, Tarak!), they both felt a spark… albeit a tiny one. It was their second date over sushi where the real magic happened, igniting a bond that has grown stronger every day since.</p>
        <p>On May 29th, 2024, Tarak planned a special outing, taking Nisha to one of her favorite activities—a hike in Mount Tamalpais. As they hiked and took in the breathtaking coastal views, their path led them into the serene Muir Woods. There, in a perfect alcove under a canopy of trees, Tarak got down on one knee and proposed. Nisha said yes!</p>
        <p>They celebrated their engagement in Sausalito, creating a cherished memory that will last a lifetime. Now, they are thrilled to share their joy with family and friends and even more excited for the journey that lies ahead together.</p>
      </div>
    </div>
  );
};