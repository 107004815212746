import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/sections/HeroSection.css';

export const HeroSection: React.FC = () => {
  const navigate = useNavigate();

  const handleRSVP = () => {
    navigate('/rsvp');
  };

  return (
    <div className="hero-section">
      <div className="hero-content">
        <p className="hero-names">Nisha and Tarak</p>
        <p className="hero-date">January 17-19, 2025</p>
        <div className="hero-location">
          <p>Richmond Marriott</p>
          <p>500 E Broad St, Richmond, VA 23219</p>
        </div>
        <button className="rsvp-button" onClick={handleRSVP}>RSVP</button>
      </div>
    </div>
  );
};
