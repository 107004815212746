import React from 'react';
import '../../css/sections/EventsSection.css';

// Remove the import for react-icons
// import { FaCalendarAlt, FaClock } from 'react-icons/fa';

interface Event {
  date: string;
  time: string;
  title: string;
  description: string;
  dressCode?: string; // Add this line
}

const events: Event[] = [
  {
    date: 'January 17, 2025',
    time: '6:00 PM',
    title: 'Sangeet / Welcome Dinner',
    description: "Celebrate with us at the Sangeet, a night filled with vibrant music, dance, and performances, where our families and friends come together in joy and anticipation for the wedding.",
    dressCode: 'Semi-formal or cocktail (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '9:00 AM',
    title: 'Baraat / Groom\'s Procession',
    description: "Join in the joyous celebration as the groom makes his entrance during the Baraat, accompanied by lively music, dancing, and the vibrant energy of family and friends leading up to the wedding ceremony.",
    dressCode: 'Formal attire (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '10:00 AM',
    title: 'Wedding Ceremony',
    description: "Join us for our traditional Hindu wedding ceremony, as we exchange vows and take our sacred pheras, marking the beginning of our shared journey.",
    dressCode: 'Formal attire (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '12:00 PM',
    title: 'Lunch',
    description: "After the ceremony, indulge in a delicious Indian lunch, offering a variety of traditional flavors to celebrate this special day.",
    dressCode: 'Formal attire (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '6:00 PM',
    title: 'Cocktail Hour',
    description: "Enjoy a relaxed cocktail hour with drinks and appetizers as we transition from day to evening, setting the tone for the festivities ahead.",
    dressCode: 'Black tie optional (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '7:00 PM',
    title: 'Reception',
    description: "Celebrate our marriage with an elegant reception, featuring a sumptuous dinner, lively dancing, and unforgettable moments shared with our loved ones.",
    dressCode: 'Black tie optional (Indian or Western)',
  },
  {
    date: 'January 19, 2025',
    time: '7:00 AM',
    title: 'Farewell Breakfast',
    description: "As we wrap up the weekend's celebrations, join us for a relaxed continental breakfast before heading out. It's a chance to say goodbye and share one last meal together. Drop by, enjoy some light fare, and safe travels!",
  },
];

const groupEventsByDate = (events: Event[]) => {
  return events.reduce((acc, event) => {
    if (!acc[event.date]) {
      acc[event.date] = [];
    }
    acc[event.date].push(event);
    return acc;
  }, {} as Record<string, Event[]>);
};

export const EventsSection: React.FC = () => {
  const groupedEvents = groupEventsByDate(events);
  return (
    <div className="section-section">
      <div className="section-container transparent-container">
        {Object.entries(groupedEvents).map(([date, dateEvents], dateIndex) => (
          <React.Fragment key={date}>
            <div className="date-separator">
              <span>{date}</span>
            </div>
            {dateEvents.map((event, eventIndex) => (
              <div key={`${date}-${eventIndex}`} className="event-card">
                <h4>{event.title}</h4>
                <p className="event-date-time">
                  {event.time}
                  {event.dressCode && ` • ${event.dressCode}`}
                </p>
                <p>{event.description}</p>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};