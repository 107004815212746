import React from 'react';
import '../css/MobileMenu.css';
import { FaTimesCircle } from 'react-icons/fa';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  sections: Array<{ id: string; title: string }>;
  activeSection: string;
  onSectionClick: (id: string) => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose, sections, activeSection, onSectionClick }) => {
  if (!isOpen) return null;

  return (
    <div className="mobile-menu">
      <button className="close-button" onClick={onClose}>
        <FaTimesCircle size={32}/>
      </button>
      <nav>
        <ul>
          {sections.map((section) => (
            <li
              key={section.id}
              className={section.id === activeSection ? 'active' : ''}
              onClick={() => {
                onSectionClick(section.id);
                onClose();
              }}
            >
              {section.title}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MobileMenu;
